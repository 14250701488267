import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

export default function Informacion() {
  return (
    <div>
      <div className="d-flex">
        <div className="w-50">
          <h2>Origen del paquete:</h2>
          <p>Desguaces vinaros</p>
          <p>964454571</p>
          <p>desguacesvinaros@gmail.com</p>
          <p>Carrer Marinada, 12</p>
          <p>25600 Balaguer</p>
          <p>Lieida, Cataluña, España</p>
        </div>
        <div className="w-50">
          <h2>Destino del paquete:</h2>
          <p>El taller de Alex 2013 S.L</p>
          <p>945891017</p>
          <p>alex@eltallerdealex.com</p>
          <p>Poligono Aldaiturriaga</p>
          <p>01470 Amurrio</p>
          <p>Álava, Pais Vasco, España</p>
        </div>
      </div>
      <div className="mt-4">
        <h2>Datos del paquete:</h2>
        <p>CAJA CAMBIOS SEAT IBIZA (635)*| #451459</p>
        <div>
          <div
            className="d-flex justify-content-evenly align-items-center mt-2"
            style={{ height: "3rem" }}
          >
            <p className="w-100 text-center">PESO</p>
            <p className="w-100 text-center">LARGO</p>
            <p className="w-100 text-center">ANCHO</p>
            <p className="w-100 text-center">ALTO</p>
          </div>
          <div
            className="d-flex justify-content-evenly align-items-center"
            style={{ height: "3rem", backgroundColor: "#F5F5F5" }}
          >
            <p className="w-100 text-center">50KG</p>
            <p className="w-100 text-center">60CM</p>
            <p className="w-100 text-center">80CM</p>
            <p className="w-100 text-center">60CM</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h2>Información del transportista:</h2>
        <div>
          <div
            className="d-flex justify-content-evenly align-items-center mt-2"
            style={{ height: "3rem" }}
          >
            <p className="w-100 text-center">SERVICIO</p>
            <p className="w-100 text-center">SEGUIMIENTO</p>
            <p className="w-100 text-center">CODIGO ENVIO</p>
            <p className="w-100 text-center">SEGURO</p>
            <p className="w-100 text-center">VALOR ASEGURADO</p>
            <p className="w-100 text-center">PRECIO</p>
          </div>
          <div
            className="d-flex justify-content-evenly align-items-center"
            style={{ height: "3rem", backgroundColor: "#F5F5F5" }}
          >
            <p className="w-100 text-center">DHL</p>
            <p className="w-100 text-center">2541043920</p>
            <p className="w-100 text-center">2541043920</p>
            <p className="w-100 text-center">SI</p>
            <p className="w-100 text-center">0€</p>
            <p className="w-100 text-center">28.92€</p>
          </div>
          <p className="mt-4">OBSERVACIONES:</p>
          <p>ID: # 451459-CAJA CAMBIOS</p>
        </div>
      </div>
      <div className="mt-4">
        <h2>Historial</h2>
        <p>Fecha creado: 31/10/24 10:01</p>
        <p>
          Fecha actualizado a: Demora de recogida (Estado carrier) /Demora de
          entrega (Estado carrier): 31/10/24 10:02
        </p>
      </div>
    </div>
  );
}
