import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";
import { StrictMode } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import keycloak from "./services/keycloak.js";
import App from "./App";
import "./index.css";

const history = createBrowserHistory();
const root = createRoot(document.getElementById("root"));

const keycloakProviderInitConfig = {
  enableLogging: true,
  checkLoginIframe: false,
  flow: "standard",
  onLoad: "check-sso",
};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakProviderInitConfig}
  >
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter location={history.location} navigator={history}>
          <App />
        </BrowserRouter>
      </Provider>
    </StrictMode>
  </ReactKeycloakProvider>
);
