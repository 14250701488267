import { useState } from "react";
import { getActions } from "./actions";
import { faSearch, faLink } from "@fortawesome/free-solid-svg-icons";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import esLocale from "date-fns/locale/es";
import alertas from "../../../../../../data/ControlGeneral/alertas.json";
import Pagination from "@mui/material/Pagination";
import InfoModal from "../../../shared/components/InfoModal";
import Info from "./Info";
import ActionsMenu from "../../../shared/components/ActionsMenu";

export default function Alertas() {
  /** States **/
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [currentTableItem, setCurrentTableItem] = useState(null);

  /** Methods **/
  const handleAction = (_action, tableItem) => {
    setOpenInfoModal(true);
    setCurrentTableItem(tableItem);
  };
  const handleCloseNewFromFile = () => {
    setOpenInfoModal(false);
  };

  /** Render **/
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <small>Transportista</small>
          <select
            defaultValue={""}
            className="h-100"
            style={{ minWidth: "200px" }}
          >
            <option value="" />
            {alertas?.selects?.transportista?.map((transportista) => {
              return (
                <option key={transportista.value} value={transportista.value}>
                  {transportista.text}
                </option>
              );
            })}
          </select>
        </div>
        <div className="d-flex flex-column">
          <small>Tipo</small>
          <select
            defaultValue={""}
            className="h-100"
            style={{ minWidth: "200px" }}
          >
            <option value="" />
            {alertas?.selects?.tipos?.map((tipo) => {
              return (
                <option key={tipo.value} value={tipo.value}>
                  {tipo.text}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <small>Fecha</small>
          <div className="d-flex gap-3">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                disableFuture
                label=""
                value={startDateValue}
                onChange={(newValue) => {
                  setStartDateValue(newValue);
                }}
                mask={"__/__/____"}
                inputFormat="dd/MM/yyyy"
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="date-picker" style={{ position: "relative" }}>
                    <input
                      className="form-control"
                      ref={inputRef}
                      {...inputProps}
                    />
                    {InputProps?.endAdornment}
                  </div>
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                disableFuture
                label=""
                value={endDateValue}
                onChange={(newValue) => {
                  setEndDateValue(newValue);
                }}
                mask={"__/__/____"}
                inputFormat="dd/MM/yyyy"
                minDate={startDateValue}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="date-picker" sx={{ position: "relative" }}>
                    <input
                      className="form-control"
                      ref={inputRef}
                      {...inputProps}
                    />
                    {InputProps?.endAdornment}
                  </div>
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="position-relative ms-auto mt-auto">
          <input type="text" name="searcher" className="form-control" />
          <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Fecha</th>
              <th className="text-center">Remitente</th>
              <th className="text-center">Destinatario</th>
              <th className="text-center">Pieza</th>
              <th className="text-center">Seguro</th>
              <th className="text-center">Asegurado</th>
              <th className="text-center">Transportista</th>
              <th className="text-center">Servicio</th>
              <th className="text-center">Total</th>
              <th className="text-center">Estado</th>
              <th className="text-center">Demora</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {alertas.tabla?.map((tabla) => {
              return (
                <tr key={tabla.id}>
                  <td className="text-center">{tabla.id}</td>
                  <td className="text-center">{tabla.fecha}</td>
                  <td className="text-center text-green">{tabla.remitente}</td>
                  <td className="text-center text-green">
                    {tabla.destinatario}
                  </td>
                  <td className="text-center">{tabla.pieza}</td>
                  <td className="text-center text-green fw-bold">
                    {tabla.seguro}
                  </td>
                  <td className="text-center">{tabla.asegurado}</td>
                  <td className="text-center">{tabla.transportista}</td>
                  <td className="text-center">
                    <p className="text-green">{tabla.servicio?.id}</p>
                    <p>{tabla.servicio?.nombre}</p>
                    <a
                      href={tabla.servicio?.url}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        color="#215732"
                        className="icon"
                      />
                    </a>
                  </td>
                  <td className="text-center">{tabla.total}</td>
                  <td className="text-center text-danger">{tabla.estado}</td>
                  <td className="text-center text-danger">{tabla.demora}</td>
                  <td className="text-center">
                    <ActionsMenu
                      options={getActions()}
                      onAction={(action) => handleAction(action, tabla)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <Pagination count={1} page={1} />
      </div>
      {openInfoModal && (
        <InfoModal
          state={openInfoModal}
          title={`Información de Envio ${currentTableItem.id}`}
          content={<Info data={currentTableItem} />}
          width={"md"}
          onClose={() => {
            handleCloseNewFromFile();
          }}
        />
      )}
    </div>
  );
}
