import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

export default function UserAvatar({ onLogout }) {
  /** Variables **/
  const { keycloak } = useKeycloak();
  const currentUser = keycloak.tokenParsed?.name.toUpperCase();

  /** States **/
  const [anchorEl, setAnchorEl] = useState(null);

  /** Methods **/
  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(currentUser),
      },
      children: `${name?.split("")[0]}`,
    };
  };
  const stringToColor = (string) => {
    let hash = 0;
    let i;
    let color = "#";
    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Avatar
        style={{ cursor: "pointer" }}
        {...stringAvatar(currentUser)}
        aria-label="Mi cuenta"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={"/mi-cuenta"} title="Mi cuenta">
          <MenuItem onClick={handleClose}>Mi cuenta</MenuItem>
        </Link>
        {currentUser?.rol_id === 1 ||
        currentUser?.rol_id === 7 ||
        currentUser?.tipo_user_id === 1 ? (
          <Link to={"/ajustes"} title="Ajustes">
            <MenuItem onClick={handleClose}>Ajustes</MenuItem>
          </Link>
        ) : (
          ""
        )}
        <MenuItem onClick={onLogout}>Cerrar sesión</MenuItem>
      </Menu>
    </div>
  );
}
