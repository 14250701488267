import { combineReducers } from "redux";
import { loadingReducer } from "./redux/loading";

const reducer = combineReducers({
  loading: loadingReducer,
});

const rootReducer = reducer;

export default rootReducer;
