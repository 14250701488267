export const getActions = () => {
  const actions = {
    actions: [
      {
        label: "Ver información",
        icon: "details",
        action: "details",
      },
      {
        label: "Regenerar envío",
        icon: "regenerate",
        action: "regenerate",
      },
      {
        label: "Cancelar envío",
        icon: "cancel",
        action: "cancel",
      },
      {
        label: "Generar nuevo envío",
        icon: "add",
        action: "generate",
      },
      {
        label: "Finalizar",
        icon: "check",
        action: "finish",
      },
    ],
    extra: [],
  };

  return actions;
};
