import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import pedidosAnulados from "../../../../../../data/ControlGeneral/pedidos_anulados.json";
import Pagination from "@mui/material/Pagination";
import { getActions } from "./actions";
import ActionsMenu from "../../../shared/components/ActionsMenu";
import { CustomTooltip } from "../../../shared/helpers/functionalities";
import { Fragment } from "react";

export default function PedidosAnulados() {
  /** Methods **/
  const handleAction = (action, tabla) => {
    console.log(action);
    console.log(tabla);
  };

  /** Render **/
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex gap-3">
        <div className="position-relative ms-auto mt-auto">
          <input type="text" name="searcher" className="form-control" />
          <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th class="text-center">Presupuesto</th>
              <th class="text-center">Fecha Anulado</th>
              <th class="text-center">Cliente</th>
              <th class="text-center">Teléfono</th>
              <th class="text-center">Pieza</th>
              <th class="text-center">Responsable</th>
              <th class="text-center">Motivo</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pedidosAnulados.tabla?.map((tabla) => {
              return (
                <tr key={tabla.id}>
                  <td className="text-center text-green">
                    {tabla.presupuesto}
                  </td>
                  <td className="text-center">{tabla.fecha_anulado}</td>
                  <td className="text-center text-green">{tabla.cliente}</td>
                  <td className="text-center">{tabla.telefono}</td>
                  <td className="d-flex justify-content-center">
                    <div>
                      <p>{tabla.pieza?.nombre}</p>
                      <p>
                        Ref: <strong>{tabla.pieza?.id}</strong>
                      </p>
                      <p className="text-green">{tabla.pieza?.nombre}</p>
                    </div>
                  </td>
                  <td className="text-center">{tabla.responsable}</td>
                  <td className="text-center">{tabla.motivo}</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      {tabla.observacion && (
                        <CustomTooltip
                          title={
                            <Fragment>
                              <small className="m-0">
                                {tabla.observacion}
                              </small>
                            </Fragment>
                          }
                        >
                          <span style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              size="lg"
                              className="action"
                              color="#ffc107"
                            />
                          </span>
                        </CustomTooltip>
                      )}
                      <div className={`${!tabla.observacion && "ms-4"}`}>
                        <ActionsMenu
                          options={getActions()}
                          onAction={(action) => handleAction(action, tabla)}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <Pagination count={1} page={1} />
      </div>
    </div>
  );
}
