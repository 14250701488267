export const getActions = () => {
  const actions = {
    actions: [
      {
        label: "Ver información",
        icon: "details",
        action: "details",
      },
      {
        label: "Copiar texto confirmación",
        icon: "duplicate",
        action: "copy",
      },
      {
        label: "Confirmar",
        icon: "check",
        action: "copy",
      },
    ],
    extra: [],
  };

  return actions;
};
