import { getNotificaciones } from "../views/Main/components/shared/services/notificaciones";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "./utils";

const initialState = [];

export const notificacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "notificaciones/fullfilled": {
      return action.payload;
    }
    default:
      return state;
  }
};

const asyncNotificaciones = makeAsyncTypes("notificaciones");

const [setPending, setFullFilled, setError] = asyncMac(asyncNotificaciones);

export const notificacionesFetchingReducer = makeFetchingReducer([
  "notificaciones/pending",
  "notificaciones/fullfilled",
  "notificaciones/rejected",
]);

export const fetchNotificaciones = (userId) => async (dispatch) => {
  dispatch(setPending());

  try {
    const response = await getNotificaciones(userId);
    const data = await response.data;
    dispatch(setFullFilled(data));
  } catch (e) {
    dispatch(setError(e.message));
  }
};

export const notificacionesReducer = notificacionReducer;
export const notificacionesStatusReducer = notificacionesFetchingReducer;
