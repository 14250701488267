import { useState } from "react";
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import General from "./components/General";
import Alertas from "./components/Alertas";
import PedidosNoConfirmados from "./components/PedidosNoConfirmados";
import PedidosAnulados from "./components/PedidosAnulados";
import EnviosNoGenerados from "./components/EnviosNoGenerados";
import Demoras from "./components/Demoras";

export default function ControlGeneral() {
  /** States **/
  const [tab, setTab] = useState("general");

  /** Methods **/
  const handleChangeTab = (_e, tab) => setTab(tab);

  /** Render **/
  return (
    <div className="content">
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChangeTab} aria-label="Control General">
            <Tab label="General" value="general" disabled={false} />
            <Tab label="Alertas" value="alertas" disabled={false} />
            <Tab
              label="Pedidos no confirmados"
              value="pedidos-no-confirmados"
              disabled={false}
            />
            <Tab
              label="Pedidos anulados"
              value="pedidos-anulados"
              disabled={false}
            />
            <Tab
              label="Envíos no generados"
              value="envios-no-generados"
              disabled={false}
            />
            <Tab label="Demoras" value="demoras" disabled={false} />
          </TabList>
        </Box>
        <TabPanel value="general">
          <General />
        </TabPanel>
        <TabPanel value="alertas">
          <Alertas />
        </TabPanel>
        <TabPanel value="pedidos-no-confirmados">
          <PedidosNoConfirmados />
        </TabPanel>
        <TabPanel value="pedidos-anulados">
          <PedidosAnulados />
        </TabPanel>
        <TabPanel value="envios-no-generados">
          <EnviosNoGenerados />
        </TabPanel>
        <TabPanel value="demoras">
          <Demoras />
        </TabPanel>
      </TabContext>
    </div>
  );
}
