import { useState } from "react";
import moment from "moment";
import GeneralMetricas from "./components/GeneralMetricas";
import DateSelector from "../../../shared/components/DateSelector";
import "./dashboard.scss";
import general from "../../../../../../data/ControlGeneral/general.json";

export default function General() {
  /** States **/
  const [startDate, setStartDate] = useState(
    moment().date() >= 26
      ? moment().date(26).format("YYYY-MM-DD")
      : moment().subtract(1, "month").date(26).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().date() >= 26
      ? moment().add(1, "month").date(25).format("YYYY-MM-DD")
      : moment().date(25).format("YYYY-MM-DD")
  );
  const [view, setView] = useState("variable");

  /** Methods **/
  const handleDateInterval = (startDate, endDate, view) => {
    setView(view);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  /** Render **/
  return (
    <div>
      <div className="d-flex align-items-center">
        <DateSelector onSelectedDate={handleDateInterval} />
      </div>

      <div className="w-100 mt-4 summary d-flex flex-column gap-4">
        <GeneralMetricas
          title={"Nacional"}
          view={view}
          data={general.generalMetricasNacional}
        />
        <GeneralMetricas
          title={"Internacional"}
          view={view}
          data={general.generalMetricasInternacional}
        />
        <GeneralMetricas
          title={"Metricas"}
          view={view}
          data={general.generalMetricasCharts}
        />
      </div>

      <div className="d-flex align-items-center">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Envios totales</th>
                <th className="text-center">Pendiente de Recoger</th>
                <th className="text-center">Recogida Fallida</th>
                <th className="text-center">Recogido</th>
                <th className="text-center">En transito</th>
                <th className="text-center">Entrega Fallida</th>
                <th className="text-center">Entregados</th>
                <th className="text-center">Incidencias</th>
                <th className="text-center">Pérdidas</th>
                <th className="text-center">Fallo</th>
              </tr>
            </thead>

            <tbody>
              {general.tabla?.map((tabla) => {
                return (
                  <tr key={tabla.id}>
                    <td className="text-center">{tabla.envios_totales}</td>
                    <td className="text-center">
                      {tabla.pendiente_de_recoger}
                    </td>
                    <td className="text-center">{tabla.recogida_fallida}</td>
                    <td className="text-center">{tabla.recogido}</td>
                    <td className="text-center">{tabla.en_transito}</td>
                    <td className="text-center">{tabla.entrega_fallida}</td>
                    <td className="text-center">{tabla.entregados}</td>
                    <td className="text-center">{tabla.incidencias}</td>
                    <td className="text-center">{tabla.perdidas}</td>
                    <td className="text-center">{tabla.fallo}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
