import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ToggleOff from "@mui/icons-material/ToggleOff";
import ToggleOn from "@mui/icons-material/ToggleOn";
import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import Image from "@mui/icons-material/Image";
import Paid from "@mui/icons-material/Paid";
import {
  Add,
  AutoFixHigh,
  Autorenew,
  Cancel,
  ChangeCircle,
  Chat,
  Check,
  Block,
  Delete,
  Done,
  Email,
  EuroSharp,
  Event,
  LocalShipping,
  MoreVert,
  Person,
  PersonOff,
  PictureAsPdf,
  ReportProblem,
  UploadFile,
  Link,
} from "@mui/icons-material";
import { Box, Divider, IconButton } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ActionsMenu({ options, onAction }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="actions-menu-button"
        aria-controls={open ? "actions-menu-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="actions-menu-menu"
        MenuListProps={{
          "aria-labelledby": "actions-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options?.actions?.map((option, i) => {
          return (
            <MenuItem
              onClick={() => onAction(option.action)}
              key={"actions-" + i}
              disableRipple
            >
              {option.icon === "edit" ? (
                <EditIcon />
              ) : option.icon === "duplicate" ? (
                <FileCopyIcon />
              ) : option.icon === "archive" ? (
                <ArchiveIcon />
              ) : option.icon === "enable" ? (
                <ToggleOn />
              ) : option.icon === "disable" ? (
                <ToggleOff />
              ) : option.icon === "details" ? (
                <RemoveRedEye />
              ) : option.icon === "image" ? (
                <Image />
              ) : option.icon === "pdf" ? (
                <PictureAsPdf />
              ) : option.icon === "pay" ? (
                <Paid />
              ) : option.icon === "check" ? (
                <Check />
              ) : option.icon === "forbidden" ? (
                <Block />
              ) : option.icon === "issue" ? (
                <ReportProblem />
              ) : option.icon === "refund" ? (
                <EuroSharp />
              ) : option.icon === "upload" ? (
                <UploadFile />
              ) : option.icon === "status" ? (
                <ChangeCircle />
              ) : option.icon === "fusion" ? (
                <AutoFixHigh />
              ) : option.icon === "add" ? (
                <Add />
              ) : option.icon === "cancel" ? (
                <Cancel />
              ) : option.icon === "delete" ? (
                <Delete />
              ) : option.icon === "user" ? (
                <Person />
              ) : option.icon === "user-slash" ? (
                <PersonOff />
              ) : option.icon === "shipment" ? (
                <LocalShipping />
              ) : option.icon === "done" ? (
                <Done />
              ) : option.icon === "date" ? (
                <Event />
              ) : option.icon === "regenerate" ? (
                <Autorenew />
              ) : option.icon === "comment" ? (
                <Chat />
              ) : option.icon === "send" ? (
                <Email />
              ) : option.icon === "link" ? (
                <Link />
              ) : null}

              {option.label}
            </MenuItem>
          );
        })}

        {options?.extra?.length > 0 ? (
          <Box>
            <Divider sx={{ my: 0.5 }} />

            {options?.extra?.map((option, i) => {
              return (
                <MenuItem
                  onClick={() => onAction(option.action)}
                  key={"actions-" + i}
                  disableRipple
                >
                  {option.icon === "edit" ? (
                    <EditIcon />
                  ) : option.icon === "duplicate" ? (
                    <FileCopyIcon />
                  ) : option.icon === "archive" ? (
                    <ArchiveIcon />
                  ) : option.icon === "enable" ? (
                    <ToggleOn />
                  ) : option.icon === "disable" ? (
                    <ToggleOff />
                  ) : option.icon === "details" ? (
                    <RemoveRedEye />
                  ) : option.icon === "image" ? (
                    <Image />
                  ) : option.icon === "pdf" ? (
                    <PictureAsPdf />
                  ) : option.icon === "pay" ? (
                    <Paid />
                  ) : option.icon === "check" ? (
                    <Check />
                  ) : option.icon === "issue" ? (
                    <ReportProblem />
                  ) : option.icon === "refund" ? (
                    <EuroSharp />
                  ) : option.icon === "upload" ? (
                    <UploadFile />
                  ) : option.icon === "status" ? (
                    <ChangeCircle />
                  ) : option.icon === "fusion" ? (
                    <AutoFixHigh />
                  ) : option.icon === "add" ? (
                    <Add />
                  ) : option.icon === "cancel" ? (
                    <Cancel />
                  ) : option.icon === "delete" ? (
                    <Delete />
                  ) : option.icon === "user" ? (
                    <Person />
                  ) : option.icon === "user-slash" ? (
                    <PersonOff />
                  ) : option.icon === "shipment" ? (
                    <LocalShipping />
                  ) : option.icon === "done" ? (
                    <Done />
                  ) : option.icon === "date" ? (
                    <Event />
                  ) : option.icon === "regenerate" ? (
                    <Autorenew />
                  ) : option.icon === "comment" ? (
                    <Chat />
                  ) : option.icon === "send" ? (
                    <Email />
                  ) : null}

                  {option.label}
                </MenuItem>
              );
            })}
          </Box>
        ) : null}
      </StyledMenu>
    </div>
  );
}
