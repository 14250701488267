export const getActions = () => {
  const actions = {
    actions: [
      {
        label: "Ver información",
        icon: "details",
        action: "details",
      },
      {
        label: "Generar incidencia",
        icon: "add",
        action: "add-incidence",
      },
      {
        label: "Seguimiento",
        icon: "details",
        action: "follow",
      },
      {
        label: "Cancelar envío",
        icon: "cancel",
        action: "cancel",
      },
      {
        label: "Generar nuevo envío",
        icon: "add",
        action: "new-shipment",
      },
      {
        label: "Descargar etiqueta",
        icon: "pdf",
        action: "download",
      },
    ],
    extra: [],
  };

  return actions;
};
