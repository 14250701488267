export const getActions = () => {
  const actions = {
    actions: [
      {
        label: "Guardar incidencia",
        icon: "check",
        action: "details",
      },
      {
        label: "Copiar texto incidente",
        icon: "duplicate",
        action: "details",
      },
    ],
    extra: [],
  };

  return actions;
};
