import { DoughnutChart } from "../../../../../shared/components/Charts/components/Doughnut";

export default function GeneralMetricas({ view, data, title }) {
  return (
    <div className="w-100 d-flex flex-column dashboard">
      <h1>{title}</h1>
      <div className="w-100 d-flex flex-column align-items-center justify-content-between my-3 gap-4">
        {(data?.resp_ext_nacional_chart ||
          data?.resp_ext_general_chart ||
          data?.resp_ext_internacional_chart) && (
          <div className="w-100 d-flex flex-wrap align-items-center justify-content-between">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center">
              <div className="col-12 col-md-4 pe-0 pe-md-3">
                {data?.resp_ext_nacional_chart && (
                  <>
                    <h3 className="text-center" style={{ color: "#03BF62" }}>
                      Nacional
                    </h3>
                    <DoughnutChart dataset={data.resp_ext_nacional_chart} />
                  </>
                )}
              </div>

              <div className="col-12 col-md-4 pe-0 pe-md-3 mt-4 mt-md-0">
                {data?.resp_ext_general_chart && (
                  <>
                    <h3 className="text-center" style={{ color: "#03BF62" }}>
                      General
                    </h3>
                    <DoughnutChart dataset={data.resp_ext_general_chart} />
                  </>
                )}
              </div>

              <div className="col-12 col-md-4 pe-0 pe-md-3 mt-4 mt-md-0">
                {data?.resp_ext_internacional_chart && (
                  <>
                    <h3 className="text-center" style={{ color: "#03BF62" }}>
                      Internacional
                    </h3>
                    <DoughnutChart
                      dataset={data.resp_ext_internacional_chart}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="card-grid w-100 d-flex flex-wrap flex-md-nowrap">
          {data?.pendiente_de_recoger && (
            <div className="w-100 w-md-50 card-item d-flex flex-column align-items-center">
              <h2>Pendiente de Recoger</h2>
              <h1>{data.pendiente_de_recoger}</h1>
            </div>
          )}
          {data?.recogida_fallida && (
            <div className="w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0">
              <h2>Recogida Fallida</h2>
              <h1>{data.recogida_fallida}</h1>
            </div>
          )}
          {data?.recogido && (
            <div className="w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0">
              <h2>Recogido</h2>
              <h1>{data.recogido}</h1>
            </div>
          )}
        </div>
        <div className="card-grid w-100 d-flex flex-wrap flex-md-nowrap">
          {data?.en_transito && (
            <div className="w-100 w-md-50 card-item d-flex flex-column align-items-center">
              <h2>En transito</h2>
              <h1>{data.en_transito}</h1>
            </div>
          )}
          {data?.entrega_fallida && (
            <div className="w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0">
              <h2>Entrega Fallida</h2>
              <h1>{data.entrega_fallida}</h1>
            </div>
          )}
          {data?.entregado && (
            <div className="w-100 w-md-50 card-item d-flex flex-column align-items-center mt-4 mt-md-0">
              <h2>Entregado</h2>
              <h1>{data.entregado}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
