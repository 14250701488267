import { useKeycloak } from '@react-keycloak/web';
import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./views/Main";
import Login from "./views/Login";
import "./styles/main.scss";

function App() {
  /** States **/
  const { keycloak } = useKeycloak();

  /** Methods **/
  const handleLogout = () => {
    keycloak.logout();
  };

  /** Render **/
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Login>
              <Main onLogout={handleLogout} />
            </Login>
          }
        />

        <Route
          path="*"
          exact={true}
          element={
            <Login>
              <Main onLogout={handleLogout} />
            </Login>
          }
        />

        <Route path="*" exact={true} element={<Navigate to="/" />}></Route>
      </Routes>
    </>
  );
}

export default App;
