import { styled, Tooltip, tooltipClasses } from "@mui/material";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "none",
    fontSize: 12,
    border: "1px solid #4cb698",
  },
}));
