import { useState } from "react";
import { getActions } from "./actions";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pedidosNoConfirmados from "../../../../../../data/ControlGeneral/pedidos_no_confirmados.json";
import Pagination from "@mui/material/Pagination";
import InfoModal from "../../../shared/components/InfoModal";
import Info from "./Info";
import ActionsMenu from "../../../shared/components/ActionsMenu";

export default function PedidosNoConfirmados() {
  /** States **/
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [currentTableItem, setCurrentTableItem] = useState(null);

  /** Methods **/
  const handleAction = (_action, tableItem) => {
    setOpenInfoModal(true);
    setCurrentTableItem(tableItem);
  };
  const handleCloseNewFromFile = () => {
    setOpenInfoModal(false);
  };

  /** Render **/
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex gap-3">
        <div className="position-relative ms-auto mt-auto">
          <input type="text" name="searcher" className="form-control" />
          <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Presupuesto</th>
              <th className="text-center">Fecha Correo Confirmación</th>
              <th className="text-center">Cliente</th>
              <th className="text-center">Teléfono</th>
              <th className="text-center">Pieza</th>
              <th className="text-center">Fecha Última Actualización</th>
              <th className="text-center">Estado</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pedidosNoConfirmados.tabla?.map((tabla) => {
              return (
                <tr key={tabla.id}>
                  <td className="text-center text-green">
                    {tabla.presupuesto}
                  </td>
                  <td className="text-center">
                    {tabla.fecha_correo_confirmacion}
                  </td>
                  <td className="text-center text-green">{tabla.cliente}</td>
                  <td className="text-center">{tabla.telefono}</td>
                  <td className="d-flex justify-content-center">
                    <div>
                      <p>{tabla.pieza?.nombre}</p>
                      <p>
                        Ref: <strong>{tabla.pieza?.id}</strong>
                      </p>
                      <p className="text-green">{tabla.pieza?.nombre}</p>
                    </div>
                  </td>
                  <td className="text-center">
                    {tabla.fecha_ultima_actualizacion}
                  </td>
                  <td className="text-center">{tabla.estado}</td>
                  <td className="text-center">
                    <ActionsMenu
                      options={getActions()}
                      onAction={(action) => handleAction(action, tabla)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <Pagination count={1} page={1} />
      </div>
      {openInfoModal && (
        <InfoModal
          state={openInfoModal}
          title={`Información de Envio ${currentTableItem.id}`}
          content={<Info data={currentTableItem} />}
          width={"md"}
          onClose={() => {
            handleCloseNewFromFile();
          }}
        />
      )}
    </div>
  );
}
