import { useState } from "react";
import { getActions } from "./actions";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomTooltip } from "../../../shared/helpers/functionalities";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  faSearch,
  faExclamationCircle,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import esLocale from "date-fns/locale/es";
import enviosNoGenerados from "../../../../../../data/ControlGeneral/envios_no_generados.json";
import Pagination from "@mui/material/Pagination";
import InfoModal from "../../../shared/components/InfoModal";
import Info from "./Info";
import ActionsMenu from "../../../shared/components/ActionsMenu";

export default function EnviosNoGenerados() {
  /** States **/
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [currentTableItem, setCurrentTableItem] = useState(null);

  /** Methods **/
  const handleAction = (_action, tableItem) => {
    setOpenInfoModal(true);
    setCurrentTableItem(tableItem);
  };
  const handleCloseNewFromFile = () => {
    setOpenInfoModal(false);
  };

  /** Render **/
  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <small>Transportista</small>
          <select
            defaultValue={""}
            className="h-100"
            style={{ minWidth: "200px" }}
          >
            <option value="" />
            {enviosNoGenerados?.selects?.transportista?.map((transportista) => {
              return (
                <option key={transportista.value} value={transportista.value}>
                  {transportista.text}
                </option>
              );
            })}
          </select>
        </div>
        <div className="d-flex flex-column">
          <small>Tipo</small>
          <select
            defaultValue={""}
            className="h-100"
            style={{ minWidth: "200px" }}
          >
            <option value="" />
            {enviosNoGenerados?.selects?.tipos?.map((tipo) => {
              return (
                <option key={tipo.value} value={tipo.value}>
                  {tipo.text}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <small>Fecha</small>
          <div className="d-flex gap-3">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                disableFuture
                label=""
                value={startDateValue}
                onChange={(newValue) => {
                  setStartDateValue(newValue);
                }}
                mask={"__/__/____"}
                inputFormat="dd/MM/yyyy"
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="date-picker" style={{ position: "relative" }}>
                    <input
                      className="form-control"
                      ref={inputRef}
                      {...inputProps}
                    />
                    {InputProps?.endAdornment}
                  </div>
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={esLocale}
            >
              <DatePicker
                disableFuture
                label=""
                value={endDateValue}
                onChange={(newValue) => {
                  setEndDateValue(newValue);
                }}
                mask={"__/__/____"}
                inputFormat="dd/MM/yyyy"
                minDate={startDateValue}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div className="date-picker" sx={{ position: "relative" }}>
                    <input
                      className="form-control"
                      ref={inputRef}
                      {...inputProps}
                    />
                    {InputProps?.endAdornment}
                  </div>
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="position-relative ms-auto mt-auto">
          <input
            type="text"
            name="searcher"
            className="form-control"
          />
          <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th class="text-center">ID</th>
              <th class="text-center">Fecha</th>
              <th class="text-center">Remitente</th>
              <th class="text-center">Destinatario</th>
              <th class="text-center">Pieza</th>
              <th class="text-center">Seguro</th>
              <th class="text-center">Asegurado</th>
              <th class="text-center">Transportista</th>
              <th class="text-center">Servicio</th>
              <th class="text-center">Total</th>
              <th class="text-center">Responsable</th>
              <th class="text-center">Estado</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {enviosNoGenerados.tabla?.map((tabla) => {
              return (
                <tr key={tabla.id}>
                  <td className="text-center">{tabla.id}</td>
                  <td className="text-center">{tabla.fecha}</td>
                  <td className="text-center text-green">{tabla.remitente}</td>
                  <td className="text-center text-green">
                    {tabla.destinatario}
                  </td>
                  <td className="text-center">{tabla.pieza}</td>
                  <td className="text-center">{tabla.seguro}</td>
                  <td className="text-center">{tabla.asegurado}</td>
                  <td className="text-center">{tabla.transportista}</td>
                  <td className="text-center">
                    <p>{tabla.servicio?.nombre}</p>
                    <p>{tabla.servicio?.tipo}</p>
                    <a
                      href={tabla.servicio?.url}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        icon={faLink}
                        color="#215732"
                        className="icon"
                      />
                    </a>
                  </td>
                  <td className="text-center">{tabla.total}</td>
                  <td className="text-center">{tabla.responsable}</td>
                  <td className="text-center">{tabla.estado}</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      {tabla.observacion && (
                        <CustomTooltip
                          title={
                            <Fragment>
                              <small className="m-0">{tabla.observacion}</small>
                            </Fragment>
                          }
                        >
                          <span style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              size="lg"
                              className="action"
                              color="#ffc107"
                            />
                          </span>
                        </CustomTooltip>
                      )}
                      <div className={`${!tabla.observacion && "ms-4"}`}>
                        <ActionsMenu
                          options={getActions()}
                          onAction={(action) => handleAction(action, tabla)}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <Pagination count={1} page={1} />
      </div>
      {openInfoModal && (
        <InfoModal
          state={openInfoModal}
          title={`Información de Envio ${currentTableItem.id}`}
          content={<Info data={currentTableItem} />}
          width={"md"}
          onClose={() => {
            handleCloseNewFromFile();
          }}
        />
      )}
    </div>
  );
}
