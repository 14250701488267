import { ToastContainer } from "react-toastify";
import { Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/shared/components/Navbar";
import ControlGeneral from "./components/ControlGeneral";
import ControlEnvios from "./components/ControlEnvios";
import Incidencias from "./components/Incidencias";
import Comunicacion from "./components/Comunicacion";
import Personalizacion from "./components/Personalizacion";
import DatosLogisticos from "./components/DatosLogisticos";
import AnalisisDatos from "./components/AnalisisDatos";
import Facturacion from "./components/Facturacion";

export default function Main({ onLogout }) {
  /** Render **/
  return (
    <div className="main-container">
      <Navbar onLogout={onLogout} />

      <Routes>
        <Route path="/" element={<Navigate to="/control-general" />} />

        <Route path="/control-general" element={<ControlGeneral />}></Route>
        <Route path="/control-envios/:page" element={<ControlEnvios />}></Route>
        <Route path="/incidencias/:page" element={<Incidencias />}></Route>
        <Route path="/comunicacion/:page" element={<Comunicacion />}></Route>
        <Route
          path="/personalizacion/:page"
          element={<Personalizacion />}
        ></Route>
        <Route
          path="/datos-logisticos/:page"
          element={<DatosLogisticos />}
        ></Route>
        <Route path="/analisis-datos/:page" element={<AnalisisDatos />}></Route>
        <Route path="/facturacion/:page" element={<Facturacion />}></Route>
      </Routes>

      <ToastContainer
        containerId="dashboard"
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </div>
  );
}
