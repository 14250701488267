import { useKeycloak } from "@react-keycloak/web";
import PropTypes from "prop-types";

const Login = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  return children;
};

Login.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Login;
